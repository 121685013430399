
import { Component, Vue } from 'vue-property-decorator';
import GeneralModule	  from '@/store/modules/general/GeneralModule';
import RolesModule        from '@/store/modules/roles/RolesModule';
import UsersModule        from '@/store/modules/users/UsersModule';
import AuthRequest		  from '@/models/dto/auth.request';
import ROUTES			  from '@/router/ROUTES';
import Button			  from '@/components/ui/button/Button.vue';
import Input 			  from '@/components/ui/input/Input.vue';
import Icon				  from '@/components/ui/icon/Icon.vue';

@Component({
	name: 'Login',
	components: {
		Button,
		Input,
		Icon,
	},
})

export default class Login extends Vue {
  public email = '';
  public password = '';
  public isEmailValid: boolean | null = null;

  get canLogin(): boolean {
    return !!this.email && !!this.password && this.isItEmail(this.email);
  }

  onEmailChange(value: string) {
    this.email = value;
  }

  onPasswordChange(value: string) {
    this.password = value;
  }

  emailValidator() {
    this.isEmailValid = this.isItEmail(this.email);
  }

  isItEmail(value: string) {
    return /.+@.+\..+/.test(value);
  }
  get currentUser()
  {
    return UsersModule.currentUser
  }
  async onLogin(e: Event) {
    e.preventDefault();

    const request = new AuthRequest();
    request.email = this.email;
    request.password = this.password;

    try {
      const logged = await GeneralModule.login(request)
      if (logged)
      {
        await RolesModule.fetchRoles();
        this.$router.push('/');
      }
    }
    catch
    {
      throw e
    }
  }
}
